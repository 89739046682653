<template>
  <v-container>
    <v-row>
      <h2>Overview</h2>
      <v-spacer></v-spacer>
      <v-btn id="btn_cancel" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
      <v-btn id="btn_save" color="primary" class="margin-right-default" @click="save()" :loading="loading">Save</v-btn>
    </v-row>
        <div v-if="user && user.validationMessages && Object.entries(user.validationMessages).length>0">
            <h3 class="margin-top-bottom primary--text"> In Order to get your profile Approved, please follow the instruction below:</h3>
            <ul>
                <li v-for="(message,index) in Object.entries(user.validationMessages)"
                     :key="index">
                    {{message[1]}}
                </li>
            </ul>
        </div>
        <div v-if="user  && user.profile.comments && user.profile.status && user.profile.status.name==='PENDING'">
            <h3 class="margin-top-bottom primary--text"> We will review your profile shortly.</h3>
        </div>
        <div v-if="user  && user.profile.comments && user.profile.status && user.profile.status.name==='REVIEW'">
            <h3 class="margin-top-bottom primary--text"> In Order to get your profile Approved, please follow the instruction below. <br/> When the changes has been made please click on "Done":</h3>
            <div class="margin-top-bottom">
                {{user.profile.comments}}
            </div>
            <v-btn id="btn_done" outlined color="primary" class="margin-top-bottom"   @click="done()" :loading="loading" >Done</v-btn>
        </div>
        <div v-if="user && user.profile.status && user.profile.status.name==='APPROVED' && user.service && user.service.length>0 && user.service.filter(ts => ts.enabled===false).length===user.service.length">
            <h3 class="margin-top-bottom primary--text"> You are now Approved. <br/> You can now make your Menus visible to the public by clicking on:
            <v-switch
                    style="display: inline-flex;"
                    hide-details
                    disabled
                    class="caption no-margin-padding "
                    label="Visible to public: Hidden"
            ></v-switch>
            inside your "Menus" Section</h3>
        </div>
        <div v-if="!isAvailable">
          <h3 class="margin-top-bottom primary--text"> Please add some available dates in the calendar</h3>
        </div>
      <v-divider class="margin-top-bottom"></v-divider>
      <v-row >
        <v-card id="date_request" style="background:#e6d6ff" class="margin-default padding" @click="goToBookings('DATE_REQUESTED')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{date_requests}}</div ><div style="display:inline;">Date Request</div> </v-card>
        <v-card id="awaiting_payment" style="background:#96e8e2" class="margin-default padding" @click="goToBookings('AWAITING_PAYMENT')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{ awaiting_payments }}</div ><div style="display:inline;">Awaiting Payment</div> </v-card>
        <v-card id="requested" style="background:#bfe9fc" class="margin-default padding" @click="goToBookings('REQUESTED')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{requests}}</div ><div style="display:inline;">Request</div> </v-card>
        <v-card id="confirmed" style="background:#b3efcb" class="margin-default padding" @click="goToBookings('CONFIRMED')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{confirmations}}</div ><div style="display:inline;">Booking</div> </v-card>
        <v-card id="cancelled" style="background:#fcc7c3" class="margin-default padding" @click="goToBookings('CANCELLED_CLIENT')"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Cancellation</div> </v-card>
        <v-card  id="messages" style="background:#fff4b3" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>
<!--          <v-card style="background:#2fd0bc" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Booking</div> </v-card>-->
<!--          <v-card style="background:#f03776" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
<!--          <v-card style="background:#1ac7ba" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
<!--          <v-card style="background:#8b1cb1" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->

      </v-row>
      <v-divider class="margin-top-bottom"></v-divider>
      <v-toolbar
          flat
      >
      <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar" class="no-margin-padding" style="display:inline;padding-left: 0px">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
        <div class="unavailable margin-left-right-default" style="padding: 2px 5px 2px 5px">Unavailable</div>
        <div class="available margin-left-right-default" style="padding: 2px 5px 2px 5px">Available</div>
        <div class="date_requested margin-left-right-default" style="padding: 2px 5px 2px 5px" >Date Requested</div>
        <div class="awaiting_payment margin-left-right-default" style="padding: 2px 5px 2px 5px">Awaiting Payment</div>
        <div class="requested margin-left-right-default" style="padding: 2px 5px 2px 5px" >Requested</div>
        <div class="confirmed margin-left-right-default" style="padding: 2px 5px 2px 5px">Confirmed</div>
<!--        Unavailable<div class="margin-left-right-default unavailable" ></div>-->
<!--        Available<div class="margin-left-right-default available" ></div>-->
<!--        Requested<div class="margin-left-right-default requested" ></div>-->
<!--        Confirmed<div class="margin-left-right-default confirmed" ></div>-->
      </v-toolbar>
      <v-calendar
          ref="calendar"
          v-model="value"
          class="test"
          :weekdays="weekday"
          :events="userLocal.events"
          type="month"
          event-overlap-mode="column"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :event-text-color="getEventTextColor"
          color="primary"
          @click:date="checkEvents"
          @click:day="checkEvents"
          @click:event="changeEventStatus"
      >
<!--        <template v-slot:day-label="{ day,present }">-->
<!--          <div style="background-color: #26c6da22">-->
<!--          {{ day }}{{present}}-->
<!--          </div>-->
<!--        </template>-->
      </v-calendar>
      <div>
        <v-row v-if="value!==undefined && eventOfTheDay() && eventOfTheDay().length>0">
          <v-col v-for="(eventTime,index) in eventTime" :key="index" class="padding-default">
            {{eventTime.displayName}}
            <v-radio-group v-if="eventOfTheDay() && eventOfTheDay().find(e=>e.eventTime.name===eventTime.name)" v-model="eventOfTheDay().find(e=>e.eventTime.name===eventTime.name).statusValue"
                           @change="changeStatus(eventOfTheDay().find(e=>e.eventTime.name===eventTime.name))"
                           :disabled="eventOfTheDayDisabled(eventTime.name)">
<!--              :label="status.displayName"-->
              <v-radio v-for="(status,index) in eventStatus" :key="index"  :value="status.name" :disabled="status.name!=='AVAILABLE' && status.name!=='UNAVAILABLE'">
                <template v-slot:label>
                  <div :class="{ unavailable:status.name==='UNAVAILABLE',available:status.name==='AVAILABLE',date_requested:status.name==='DATE_REQUESTED',awaiting_payment:status.name==='AWAITING_PAYMENT',requested:status.name==='REQUESTED',confirmed:status.name==='CONFIRMED',cancelled:status.name==='CANCELLED' }" style="padding: 2px 5px 2px 5px">{{status.displayName}}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import Constants from '../../constants';
    export default {
      name: "Overview",

      data() {
          return {
            date_requests:0,
            awaiting_payments:0,
            requests:0,
            confirmations:0,
            value:undefined,
            loading:false,
            weekday: [1, 2, 3, 4, 5, 6, 0],
            events:[],
            lastTimeStatusValue:{"BREAKFAST":"AVAILABLE","LUNCH":"AVAILABLE","DINNER":"AVAILABLE"},
            userLocal:undefined
          };
      },
      mounted(){
        // const min = new Date(`${start.date}T00:00:00`).getTime()
        // const max = new Date(`${end.date}T23:59:59`).getTime()
        //
        // this.events.push({
        //   name: "free",
        //   color: "black",
        //   start:new Date("2020-11-24"),
        //   end:undefined,
        //   timed:false,
        // })
        // this.log(this.$refs.calendar);
        this.$refs.calendar.move(0)
      },
      methods: {
        save() {
          this.loading=true;
          this.userLocal.events.forEach(e=>{
              e.date=e.start.toISOString().substring(0,10);
          })
          this.$parent.save(this);
        },
        cancel(){
          this.requests=0;
          this.confirmations=0;
          this.date_requests=0;
          this.awaiting_payments=0;
          let copy=JSON.parse(JSON.stringify(this.$parent.user));
          this.$set(copy, 'events',copy.events);
          if (copy.events===undefined){
            copy.events=[];
          }else{
            this.requests=0;

            this.confirmations=0;
            copy.events.forEach(e=>{
              e.start=new Date(e.date);
              e.statusValue=e.status.name;
              e.name=e.eventTime.displayName;
              e.status.name==="REQUESTED"?this.requests++:"";
              e.status.name==="CONFIRMED"?this.confirmations++:"";
              e.status.name==="DATE_REQUESTED"?this.date_requests++:"";
              e.status.name==="AWAITING_PAYMENT"?this.awaiting_payments++:"";
            })
            // copy.events=copy.events.sort((a, b) => a.eventTime.order - b.eventTime.order);
          }

          this.userLocal=copy;
        },
        done() {
          this.loading=true;
          this.$store.dispatch('reviewDone',this.userLocal)
              .then((response) => {
                if (response !== undefined && response.statusCode === 200) {
                  this.$store.dispatch('showSnackbar', {
                    text: Constants.SAVED,
                    color: "primary"
                  })
                }
              }).finally(()=>{this.loading=false;});
        },
        getEventColor (event) {
          if (event.status.name==="AVAILABLE"){
            return "available";
          }else if (event.status.name==="UNAVAILABLE"){
            return "unavailable";
          }else if (event.status.name==="DATE_REQUESTED"){
            return "date_requested";
          }else if (event.status.name==="AWAITING_PAYMENT"){
            return "awaiting_payment";
          }else if (event.status.name==="CONFIRMED" ){
            return "confirmed";
          }else if (event.status.name==="REQUESTED" ){
            return "requested";
          }else{
            return "available";
          }

          // return event.class
        },
        getEventTextColor (event) {
          if (event.status.name==="AVAILABLE"){
            return "black";
          }else if (event.status.name==="UNAVAILABLE"){
            return "white";
          }else if (event.status.name==="CONFIRMED" || event.status.name==="AWAITING_PAYMENT" || event.status.name==="REQUESTED" || event.status.name==="DATE_REQUESTED"){
            return "black";
          }else{
            return "white";
          }

          // return event.class
        },
        getClass (event) {
          console.log("getClass" + event);
          return "black--text"
          // if (event.status.name==="AVAILABLE"){
          //   return "grey";
          // }else if (event.status.name==="UNAVAILABLE"){
          //   return "black";
          // }else if (event.status.name==="CONFIRMED"){
          //   return "confirme-event";
          // }else if (event.status.name==="REQUESTED"){
          //   return "#87cefa";
          // }else{
          //   return "grey";
          // }
        },
        findEventStatus(name) {
          if (this.eventStatus!==undefined){
            return this.eventStatus.find(s=>s.name===name);
          }
        },
        findEventTime(name) {
          if (this.eventTime!==undefined){
            return this.eventTime.find(s=>s.name===name);
          }
        },
        checkEvents(event){
          console.log(this.value);
          console.log(event.date);
          this.value=event.date;
          let ev=this.userLocal.events.filter(e=>e.start!==undefined && new Date(e.start).toDateString()===new Date(event.date).toDateString());
          ev=ev.sort((a, b) => a.eventTime.order - b.eventTime.order);
          this.log(ev);
          let lunchFound,dinnerFound=false;
          if ( ev.length>0){
            this.eventsOfTheDay=ev;

            this.eventsOfTheDay.forEach(value => {
              if (value.eventTime.name==="LUNCH"){
                lunchFound=true;
              }else if (value.eventTime.name==="DINNER"){
                dinnerFound=true
              }
              this.lastTimeStatusValue[value.eventTime.name]=value.statusValue
            });

            // this.lastTimeStatusValue[ev.time.name]=ev.statusValue;
            console.log(ev);
          }
            // this.events.push({
            //   name: "Morning",
            //   color: "blue",
            //   start:new Date(this.value),
            //   end:undefined,
            //   timed:false,
            //   status:this.findEventStatus(this.lastTimeStatusValue["MORNING"]),
            //   statusValue:this.lastTimeStatusValue["MORNING"],
            //   time:this.findEventTime("MORNING")
            // })
            if (!lunchFound){
              this.userLocal.events.push({
                name: "Lunch",
                color: "blue",
                start:new Date(event.date),
                end:undefined,
                timed:false,
                status:this.findEventStatus(this.lastTimeStatusValue["LUNCH"]),
                statusValue:this.lastTimeStatusValue["LUNCH"],
                eventTime:this.findEventTime("LUNCH")
              })
            }
            if (!dinnerFound){
              this.userLocal.events.push({
                name: "Dinner",
                color: "blue",
                start:new Date(event.date),
                end:undefined,
                timed:false,
                status:this.findEventStatus(this.lastTimeStatusValue["DINNER"]),
                statusValue:this.lastTimeStatusValue["DINNER"],
                eventTime:this.findEventTime("DINNER")
              })
            this.$set(this.userLocal, 'events',this.userLocal.events);
          }
          console.log(event)
        },
        changeStatus(event){
          this.lastTimeStatusValue[event.eventTime.name]=event.statusValue;
          event.status=this.findEventStatus(event.statusValue);
        },
        changeEventStatus(event){
              if (event.event.statusValue==='UNAVAILABLE'){
                  event.event.statusValue='AVAILABLE';
              }else if (event.event.statusValue==='AVAILABLE'){
                  event.event.statusValue='UNAVAILABLE';
              }else if (event.event.bookingReference!==undefined){
                  this.$root.bookingToLoad=event.event.bookingReference;
                  this.$router.push("/dashboard/bookings");
              }
            this.changeStatus(event.event);
        },
        eventOfTheDay(){
          let ev;
          if (this.userLocal.events!==undefined && this.userLocal.events.length>0){
            ev=this.userLocal.events.filter(e=>e.start!==undefined && new Date(e.start).toDateString()===new Date(this.value).toDateString());
          }
          return ev;
        },
        eventOfTheDayDisabled(eventTime){
          let ev;
          if (this.userLocal.events!==undefined && this.userLocal.events.length>0){
            ev=this.userLocal.events.filter(e=>e.eventTime.name===eventTime && e.start!==undefined && new Date(e.start).toDateString()===new Date(this.value).toDateString());
            if (ev===undefined || ev[0].statusValue==="AVAILABLE" || ev[0].statusValue==="UNAVAILABLE"){
              return false
            }
          }
          return true;
        },
        goToBookings(status){
          this.$root.bookingStatus=this.$store.state.bookingStatus.find(s => s.name===status);
          this.$router.push({path: `bookings`})
        },

      },
      computed: {
        isAvailable(){
          if (this.userLocal.events!==undefined && this.userLocal.events.length>0 && this.userLocal.events.filter(e=>e.statusValue==="AVAILABLE").length > 0){
            return true
          }else {
            return false;
          }
        },
        user() {
          if (this.$parent.user!=undefined){
            this.cancel();
          }
          return this.$parent.user;
        },
        eventTime() {
          // excluding morning for now
          if (this.$store.state.eventTime){
            this.log(this.$store.state.eventTime.filter(s => s.name!=="BREAKFAST"));
            return this.$store.state.eventTime.filter(s => s.name!=="BREAKFAST");
          }
          return undefined;
        },
        eventStatus() {
          if (this.$store.state.eventStatus){
            return this.$store.state.eventStatus.filter(s => s.name!=="RESERVED");
          }else{
            return this.$store.state.eventType;
          }
        },
      },
      beforeRouteLeave (to, from, next) {
        console.log("route leave");
        let copyLocal=JSON.parse(JSON.stringify(this.userLocal));
        if (Object.keys(copyLocal.userDetails).length === 0){
          delete copyLocal.userDetails;
        }

        //delete copyLocal.validationMessages;
        copyLocal.events.forEach(e=>{
          delete e.start;
          delete e.statusValue;
          delete e.name;
        })
        if (Object.keys(copyLocal.events).length === 0){
          delete copyLocal.events;
        }

        let local=JSON.stringify(copyLocal);
        let remote=JSON.stringify(this.$parent.user);
        // console.log(local);
        // console.log(remote);
        if (local !== remote){
          this.$parent.dirty=true;
          this.$parent.next=next;
        }else{
          next();
        }
      }
    }
</script>

<style scoped>

</style>