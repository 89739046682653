<template>
    <v-container v-if="user">
            <v-row class="margin-bottom">
                <h2>Documents</h2>
                <v-spacer></v-spacer>
<!--                <v-btn outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>-->
<!--                <v-btn outlined color="primary" class="margin-right-default" :disabled="!valid" type="submit">Save</v-btn>-->
            </v-row>
            <div v-if="userLocal.documents!==undefined">
                <v-row v-for="(item) in userLocal.documents" :key="item.id">
                    <v-col cols="12" md="5">
                        {{item.documentType.displayName}}
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-btn id="btn_download" icon color="primary" @click.prevent="openLink('/api/user/document/'+user.id+'/'+item.filename)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                        <a :href="'/api/user/document/'+user.id+'/'+item.filename" target="_blank">Open ({{item.filename}})</a>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-btn id="btn_delete" outlined small @click="deleteDocument(item)" :disabled="userLocal.profile!=undefined && userLocal.profile.status!=undefined && userLocal.profile.status.name==='APPROVED'" :loading="item.loadingDelete" >Delete</v-btn>
                    </v-col>
                </v-row>
            </div>
        <v-form
                ref="form"
                v-model="valid"
        >
            <v-row class="row" align="center">
                <v-col cols="12" md="5">
                    <v-select
                        id="type"
                            v-if="documentTypes"
                            v-model="document.type"
                            :items="documentTypes"
                            item-text="displayName"
                            item-value="name"
                            label="Document"
                            :rules="[v => !!v && v.length !== 0 || 'You must select one Document Type!']"
                            required
                    ></v-select>
                </v-col>
                <v-col cols="12" md="5" >
                    <v-file-input
                        id="file"
                            label="File input"
                            v-model="document.file"
                            :show-size=true
                            accept="image/*,.pdf,.doc,.docx"
                            :rules="inputFileRule"
                            counter
                    ></v-file-input>
                </v-col>
                <v-col cols="2" align="center">
                    <v-btn id="btn_upload" outlined small :disabled="!valid" :loading="loading" @click="upload()">Upload</v-btn>
                </v-col>

            </v-row>
        </v-form>
      <div class="caption"> <v-icon>mdi-help-circle-outline</v-icon> For security reason we ask for an Id document</div>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import {mapState} from "vuex";
    import Constants from '../../constants';
    export default {
        name: "Documents",
        data() {
            return {
                userLocal:undefined,
                document:{},
                valid:true,
                inputFileRule: [
                    value => !!value  || 'This field is required!',
                    value => !value || value.size < 10000000 || 'File size should be less than 5 MB!',
                ],
                loading:false,
            };
        },
        methods: {
          openLink(url){
            console.log(url)
            window.open(url, '_blank');
          },
            cancel(){
                let copy=JSON.parse(JSON.stringify(this.$parent.user));
                this.$set(copy, 'documents',copy.documents);
                this.userLocal=copy;
            },
            validate () {
                this.$refs.form.validate()
            },
            upload() {
                this.loading=true;
                this.validate();
                console.log(this.valid);
                this.$store.dispatch('uploadDocument', {document:this.document, user:this.user})
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SAVED,
                                color: "primary"
                            });
                            this.$refs.form.reset()
                        }
                    }).finally(()=>{this.loading=false;});
            },
            deleteDocument(document) {
                console.log(document);
                this.$set(document,"loadingDelete",true);
                this.$store.dispatch('deleteDocument', {document:document, user:this.user})
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.DELETED,
                                color: "primary"
                            })
                        }
                    }).finally(()=>{document.loadingDelete=false;});
            },
        },
        computed: {
            ...mapState({
                documentTypes: 'documentType'
            }),
            user() {
                if (this.$parent.user!=undefined){
                    this.cancel();
                }
                return this.$parent.user;
            },
        },

    }
</script>

<style scoped>

</style>