<template>
    <v-container v-if="user">
        <v-row class="margin-bottom">
            <h2>Company (optional)</h2>
            <v-spacer></v-spacer>
            <v-btn id="btn_cancel" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
            <v-btn id="btn_save" @click="save" color="primary" class="margin-right-default" :loading="loading">Save</v-btn>
        </v-row>
        <h2></h2>
        <v-text-field
            id="name"
                label="Company name / Business Name / Trading Name"
                placeholder="Company name or Business Name or Trading Name"
                v-model="userLocal.company.name"
        >
        </v-text-field>
        <v-text-field
            id="abn"
            type="number"
                label="ABN"
                v-model="userLocal.company.abn"
        >
        </v-text-field>
      <v-autocomplete
          :no-filter="true"
          id="ac_address"
          clearable
          v-model="address"
          :items="addressItems"
          :loading="isLoading"
          :search-input.sync="lookupAddress"
          item-text="place_name"
          label="Location"
          hide-no-data
          placeholder="Start typing your address"
          prepend-icon=""
          append-icon=""
          return-object
          @change="getLocation"
          auto-select-first
      ></v-autocomplete>
        <v-text-field

            disabled
                label="Address"
                v-model="userLocal.company.address"
        >
        </v-text-field>
        <v-text-field
            id="address"
            disabled
                label="City"
                v-model="userLocal.company.city"
        >
        </v-text-field>
        <v-text-field
            id="region"
            disabled
            label="Region/State"
            v-model="userLocal.company.region"
        >
      </v-text-field>
        <v-text-field
            id="postcode"
            disabled
                label="Post Code"
                v-model="userLocal.company.postcode"
        >
        </v-text-field>
        <v-text-field
            id="phone"
            type="number"
                label="Business Phone Number"
                v-model="userLocal.company.phone"
        >
        </v-text-field>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    export default {
        name: "Company",
        data() {
            return {
                userLocal:undefined,
                address:undefined,
                fullAddress:undefined,
                addressItems:undefined,
                isLoading:false,
                lookupAddress:undefined,
                loading:false,
            };
        },
        methods: {
            cancel(){
                let copy=JSON.parse(JSON.stringify(this.$parent.user));
                this.$set(copy, 'company',copy.company);
                if (copy.company===undefined){
                    copy.company={};
                }
              if (copy.company.fullAddress!==undefined){
                this.address={place_name: copy.company.fullAddress };
                this.addressItems = [{place_name: copy.company.fullAddress }];
              }
                this.userLocal=copy;
            },
            save() {
              if (this.address!=undefined){
                this.userLocal.company.fullAddress=this.address.place_name;
              }
              this.$parent.save(this);
            },
          getLocation(val){
            if (val!==undefined && val.context!==undefined){
              let place;
              val.context.forEach( c => {
                if (c.id.includes("postcode")){
                  this.userLocal.company.postcode=c.text;
                }else if (c.id.includes("locality")){
                  this.userLocal.company.city=c.text;
                }else if (c.id.includes("place")){
                  place=c.text;
                }else if (c.id.includes("country")){
                  this.userLocal.company.country=c.text;
                }else if (c.id.includes("region")){
                this.userLocal.company.region= c.text;
                if (c.short_code!==undefined){
                  this.userLocal.company.region= c.short_code.substring(c.short_code.indexOf("-")+1,c.short_code.length);
                }
              }
              });
              if (this.userLocal.company.city===undefined && place !==undefined){
                this.userLocal.company.city=place;
              }
              this.userLocal.company.address=val.address + " " + val.text;
            }else{
              this.userLocal.company.postcode=undefined;
              this.userLocal.company.city=undefined;
              this.userLocal.company.country=undefined;
              this.userLocal.company.address=undefined;
              this.userLocal.company.region=undefined;
            }
            // this.userLocal.company.location=val.geometry;
            return val;
          },
        },
        computed: {
          user() {
            if (this.$parent.user != undefined) {
              this.cancel();
            }
            return this.$parent.user;
          },
        },
      watch: {
          lookupAddress (val) {
            if (val===undefined || val===null || val.length<4) {
              // if (val===null && this.userLocal.userDetails.fullAddress!==undefined){
              //   val=this.userLocal.userDetails.fullAddress;
              //   this.address={};
              //   this.address.place_name=val;
              //   this.addressItems=[];
              //   this.addressItems.push(this.address);
              //   return
              // }else{
              return
              // }
            }
            // Items have already been loaded
            // if (this.items !==undefined && this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            let that=this;
            // using mapbox
            this.$store.dispatch('lookupAddressMapbox',val).
            then(response => {
              that.addressItems=response;
            })
                .finally(() => (this.isLoading = false));
          },
        },
        beforeRouteLeave (to, from, next) {
            console.log("route leave");
            let copyLocal=JSON.parse(JSON.stringify(this.userLocal));
            if (Object.keys(copyLocal.company).length === 0){
                delete copyLocal.company;
            }
            let local=JSON.stringify(copyLocal);
            let remote=JSON.stringify(this.$parent.user);
            if (local !== remote){
                this.$parent.dirty=true;
                this.$parent.next=next;
            }else{
                next();
            }
        }
    }
</script>

<style scoped>

</style>