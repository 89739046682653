<template>
    <v-container v-if="user">
        <v-form
                ref="form"
                v-model="valid"
                >
            <v-row class="margin-bottom">
                <h2>Photos</h2>
                <v-spacer></v-spacer>
            </v-row>
            <div v-if="serviceTypes.length===0">Please add a Menu first</div>
            <v-tabs v-if="serviceTypes.length!==0"
                    fixed-tabs
                    v-model="tab"
                    background-color="primary"
            >
                <v-tab v-for="(serviceType) in serviceTypes"
                       :key="serviceType.name"
                >
                    <div v-if="serviceType">
                        {{serviceType.displayName}}

                    </div>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                        v-for="serviceType in serviceTypes"
                        :key="serviceType.name"
                >
                    <v-row class="row" align="center">
                        <v-col cols="12" md="5" >
                            <v-file-input
                                id="file"
                                    label="File input"
                                    v-model="photo.file"
                                    :show-size=true
                                    accept="image/jpeg,image/png"
                                    :rules="inputFileRule"
                                    counter
                                    required
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12" md="5" >
                            <v-text-field
                                id="description"
                                label="Description"
                                v-model="photo.description"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" align="center">
                            <v-btn id="btn_photo_add" outlined small @click="addPhoto()" :disabled="!valid" :loading="loading">Upload</v-btn>
                        </v-col>
                    </v-row>
                    <v-card>
                        <div class="gallery" v-if="photos">
                            <div
                                    v-for="(photo, index) in  photos"
                                    :key="index"
                                    class="pic"
                            >
                                <img :src="photo.photoPath+'low.'+photo.photoFilename" alt="photo.description" class="gallery-pic" @click="photoDetails=true;photoIndex=index">
                                <div>{{(!photo.description)?photo.description:""}}</div>
                                <v-btn id="btn_photo_delete" icon color="primary" @click="deletePhoto(photo)" >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                    <v-dialog v-model="photoDetails" fullscreen content-class="caroussel-dialog-fullscreen" hide-overlay>
                        <v-btn id="btn_carousel_close" icon dark @click="photoDetails = false" style="position: absolute;z-index: 1;">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-carousel v-if="photos"
                                    show-arrows
                                    hide-delimiters
                                    class="caroussel-fullscreen"
                                    v-model="photoIndex"
                                    height="100%"
                        >
                            <v-carousel-item contain
                                             v-for="(photo) in photos"
                                             :key="photo.id"
                                             :src="photo.photoPath+'high.'+photo.photoFilename"

                            ></v-carousel-item>
                        </v-carousel>
                    </v-dialog>
                </v-tab-item>
            </v-tabs-items>

        </v-form>
    </v-container>

</template>

<script>
    import Constants from '../../constants';
    import {} from 'vuex';
    export default {
        name: "Photos",
        data() {
            return {
                tab: 0,
                service: undefined,
                userLocal: undefined,
                valid: true,
                photo:{},
                inputFileRule: [
                    value => !!value  || 'This field is required!',
                    value => !value || value.size < 10000000 || 'File size should be less than 10 MB!',
                ],
                photoDetails:false,
                photoIndex:undefined,
                loading:false,
            }
        },
        methods: {
            deletePhoto(photo){
                this.$store.dispatch('deletePhoto',  {photo:photo,user:this.userLocal})
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SAVED,
                                color: "primary"
                            })
                        }
                    });
            },
            addPhoto(){
                console.log("save")
                this.photo.serviceType=this.serviceTypes[this.tab];
                this.loading=true;
                this.$store.dispatch('uploadPhoto', {photo:this.photo,user:this.userLocal})
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SAVED,
                                color: "primary"
                            })
                            this.userLocal=response.result;
                            this.$refs.form.reset();
                        }
                    }).finally(()=>{this.loading=false;})
            },
            cancel() {
                let copy=JSON.parse(JSON.stringify(this.$parent.user));
                this.userLocal = copy;
                if (this.userLocal.photos===undefined){
                    this.userLocal.photos={};
                }
            },
        },
        computed: {
            serviceTypes(){
                let serviceTypes=[];
                if (this.userLocal.service!=undefined){
                    this.userLocal.service.forEach(value => serviceTypes.push(value.serviceType));
                }

                // if (this.userLocal.photos!==undefined && this.userLocal.photos.length>0){
                //     this.userLocal.photos.forEach(photo => {
                //         if (serviceTypes.length===0 || serviceTypes.filter(st => photo.serviceType!==undefined && st.name === photo.serviceType.name).length === 0){
                //             serviceTypes.push(photo.serviceType)
                //         }
                //     } );
                // }
                console.log(serviceTypes);
                serviceTypes=serviceTypes.sort((a, b) => {
                  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }

                  // names must be equal
                  return 0;
                });
                return serviceTypes;
            },
            photos(){
                console.log("serviceTypes")
                console.log(this.serviceTypes);
                let photos;
                if (this.userLocal.photos!==undefined && Object.keys(this.userLocal.photos).length!==0){
                    photos=this.userLocal.photos.filter(photo => photo.serviceType.name===this.serviceTypes[this.tab].name);
                    console.log(photos);
                }
                return photos;
            },
            user() {
                if (this.$parent.user!=undefined){
                    this.cancel();
                }
                return this.$parent.user;
            },
        }
    }
</script>

<style scoped>

</style>