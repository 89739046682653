<template>
    <v-container v-if="loggedin && user">
        <v-row class="margin-bottom">
            <h2>Bank Details</h2>
            <v-spacer></v-spacer>
            <v-btn id="btn_cancel" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
            <v-btn id="btn_save" @click="save" color="primary" class="margin-right-default" :loading="loading">Save</v-btn>
        </v-row>
        <v-text-field
            id="bank_name"
                label="Bank Name"
                v-model="userLocal.bank.name"
        >
        </v-text-field>
        <v-text-field
            id="account_holder"
            label="Account Holder Name"
            placeholder="Account holder name"
            v-model="userLocal.bank.accountHolder"
        >
        </v-text-field>
        <v-text-field
            id="account_name"
                label="Account Name"
                placeholder="Account name"
                v-model="userLocal.bank.accountName"
        >
        </v-text-field>
        <v-text-field
            id="bsb"
            type="number"
                label="BSB"
                v-model="userLocal.bank.bsb"
        >
        </v-text-field>
        <v-text-field
            id="account_number"
            type="number"
                label="Account Number"
                v-model="userLocal.bank.accountNumber"
        >
        </v-text-field>
      <div v-if="userLocal.stripe!==undefined">Bank account registered with stripe {{userLocal.stripe.bankAccountHolder}} BSB:{{userLocal.stripe.routing}} Account Number last 4 digits:{{userLocal.stripe.last4}} (Used for the payout) </div>
      <div v-if="userLocal.stripe==undefined" class="caption"> <v-icon>mdi-help-circle-outline</v-icon> We will need your bank account details to process the payout when the booking is completed</div>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    // import Constants from '../../constants';
    export default {
        name: "Bank",
        data() {
            return {
                userLocal:undefined,
                loading:false,
            };
        },
        methods: {
            cancel(){
                let copy=JSON.parse(JSON.stringify(this.$parent.user));
                this.$set(copy, 'bank',copy.bank);
                if (copy.bank===undefined){
                    copy.bank={};
                }
                this.userLocal=copy;
            },
            save() {
                this.$parent.save(this);
            },
        },
        computed: {
            user() {
                if (this.$parent.user!=undefined){
                    this.cancel();
                }
                return this.$parent.user;
            }
        },
        beforeRouteLeave (to, from, next) {
            console.log("route leave");

            let copyLocal=JSON.parse(JSON.stringify(this.userLocal));
            if (Object.keys(copyLocal.bank).length === 0){
                delete copyLocal.bank;
            }
            let local=JSON.stringify(copyLocal);
            let remote=JSON.stringify(this.$parent.user);
            if (local !== remote){
                this.$parent.dirty=true;
                this.$parent.next=next;
            }else{
                next();
            }
        }
    }
</script>

<style scoped>

</style>