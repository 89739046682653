<template>
  <v-container >
    <v-row class="margin-bottom">
      <h2>Overview</h2>
      <v-spacer></v-spacer>
<!--      <v-btn outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>-->
<!--      <v-btn outlined color="primary" class="margin-right-default" @click="save()" :loading="loading">Save</v-btn>-->
    </v-row>
      <v-divider class="margin-top-bottom"></v-divider>
      <v-row >
<!--          <v-card style="background:#bfe9fc" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Request</div> </v-card>-->
        <v-card id="confirmed" style="background:#b3efcb" class="margin-default padding" @click="goToBookings('CONFIRMED')"><div style="display:inline;font-size: 55px;line-height: 55px;">{{confirmations}}</div ><div style="display:inline;">Booking</div> </v-card>
<!--        <v-card style="background:#fcc7c3" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Cancellation</div> </v-card>-->
<!--        <v-card style="background:#fff4b3" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
<!--          <v-card style="background:#2fd0bc" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Booking</div> </v-card>-->
<!--          <v-card style="background:#f03776" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
<!--          <v-card style="background:#1ac7ba" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->
<!--          <v-card style="background:#8b1cb1" class="margin-default padding"><div style="display:inline;font-size: 55px;line-height: 55px;">0</div ><div style="display:inline;">Messages</div> </v-card>-->

      </v-row>
      <v-divider class="margin-top-bottom"></v-divider>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    // import Constants from '../../constants';
    export default {
        name: "Overview",

        data() {
            return {
              value:undefined,
              loading:false,
              userLocal:undefined,
              confirmations:0,
            };
        },
        mounted(){
          // this.log(new Date());
          // let that=this;
          // this.$store.dispatch('getChefsAdminStatusPending').then(function(response){
          //       that.pendings = response.result;
          //       that.loading = false;
          //     }
          // );
        },
        methods: {
          goToBookings(status){
            this.$root.bookingStatus=this.$store.state.bookingStatus.find(s => s.name===status);
            this.$router.push({path: `bookings`})
          },
        },
        computed: {

        },
    }
</script>

<style scoped>

</style>